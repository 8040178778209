import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { CSSObject } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { faGalleryThumbnails } from "@fortawesome/pro-regular-svg-icons";

import BlogPostCard from "./BlogPostCard";
import { BlogPost as BlogPostType } from "@Types/services/post";
import colors from "@Variables/colors";
import { data } from "./dummyData";
import Error from "../Error";
import { formatDate } from "../../emotion/helpers/formatDate";
import { breakpointUp } from "@Variables/breakpoints";

import {
  AttachmentImage,
  Body,
  BodySm,
  Button,
  CardColumn,
  Column,
  Container,
  Heading,
  Row,
  SocialMediaShare,
} from "@Components";

import {
  fontSize5xl,
  fontSize6xl,
  fontSizeMd4xl,
  fontSizeMd5xl,
  fontSizeSm,
  fontSizeSmLg,
  marginBottomLg,
  marginBottomSm,
  marginLeft2xl,
  marginLeftZero,
  marginTopLg,
  marginYLg,
  paddingBottomLg,
  paddingLeft2xl,
  paddingLeftMd,
  paddingTopSm,
  paddingYLg,
} from "@Styles";

import { useBreakpoints } from "@Hooks/useBreakpoints";

const buttonStyle: CSSObject[] = [
  marginBottomLg,
  {
    [breakpointUp["xs"]]: [marginLeftZero],
    [breakpointUp["md"]]: [marginLeft2xl],
  },
];

const dateStyle: CSSObject[] = [paddingTopSm, fontSizeSm];

const headingSummaryStyle: CSSObject[] = [paddingTopSm, fontSizeSmLg];

const headingTitleStyle: CSSObject[] = [
  marginBottomSm,
  marginTopLg,
  {
    [breakpointUp["xs"]]: [fontSize6xl],
    [breakpointUp["md"]]: [fontSize6xl],
  },
];

const imgStyle: CSSObject = {
  heigth: "100%",
  maxWidth: "100%",
};

const moreBlogsStyle: CSSObject[] = [
  marginYLg,
  {
    [breakpointUp["xs"]]: [fontSize5xl],
    [breakpointUp["md"]]: [fontSizeMd5xl, paddingLeft2xl],
  },
];

const socialMediaTitleStyle: CSSObject[] = [
  marginBottomSm,
  paddingLeftMd,
  fontSize5xl,
  {
    [breakpointUp["xs"]]: [fontSize6xl, marginLeft2xl],
    [breakpointUp["md"]]: [fontSizeMd4xl, marginLeftZero],
  },
];

const BlogPost = () => {
  const [blogPost, setBlogPost] = useState<BlogPostType>();
  const [morePosts, setMorePosts] = useState<BlogPostType[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const { _id: name } = useParams();

  const { i18n, t } = useTranslation();
  const { isSmallScreen } = useBreakpoints();

  const loadBlogPost = async (name: string) => {
    if (!name) {
      return setHasError(true);
    }
    // TODO: Replace this with your actual data fetching logic
    const blogPost = data.find((blogPost) => blogPost.name === name);
    const morePosts = data.filter((post) => post.id !== blogPost?.id);
    setBlogPost(blogPost);
    setMorePosts(morePosts);
  };

  useEffect(() => {
    if (name) {
      loadBlogPost(name);
    }
  }, [name]);

  if (hasError) {
    return <Error />;
  }

  // this function remove inherited width and height attributes from image tags and replace them with set values
  const sanitizeHtmlAndUpdateImages = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const imgTags = doc.querySelectorAll("img");
    const figures = doc.querySelectorAll("figure");

    //remove inherited margin attributes
    figures.forEach((figure) => {
      figure.style.margin = "0";
    });

    imgTags.forEach((img) => {
      img.removeAttribute("width");
      img.removeAttribute("height");
      img.classList.add("img");
    });

    // Sanitize the modified HTML
    return DOMPurify.sanitize(doc.body.innerHTML);
  };

  return (
    <>
      <Container size="md">
        {/* header breadcrumb*/}
        <Row>
          <Column span={{ default: 12, lg: 12 }}>
            <Heading
              scale={2}
              variant="display"
              color="primary"
              css={headingTitleStyle}
            >
              {blogPost?.title}
            </Heading>
            <Heading
              color="label"
              css={headingSummaryStyle}
              scale={5}
            >
              {blogPost?.summary}
            </Heading>
            <BodySm css={dateStyle}>
              {/* this will be update laster to either createdAt or publishedAt  */}
              {formatDate("2030-12-31T11:26:30.417Z", i18n.language, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </BodySm>
            <div css={[marginYLg]}>
              <AttachmentImage
                imageCss={[{ borderRadius: "0.5rem" }]}
                alt={blogPost?.title}
                attachment={blogPost?.featuredImage}
                size="xl"
              />
            </div>
          </Column>
          <Column>
            {/* Main content */}
            {blogPost?.content && (
              <div css={[paddingYLg]}>
                <Body
                  css={{
                    "& img.img": imgStyle,
                  }}
                >
                  {parse(sanitizeHtmlAndUpdateImages(blogPost.content))}
                </Body>
              </div>
            )}
            <div
              css={[
                paddingBottomLg,
                { borderBottom: `1px solid ${colors.gray3}` },
              ]}
            >
              <Column
                span={{ default: 12, md: 9, sm: 12, xs: 12 }}
                offset={{ default: 0, md: 3 }}
              >
                <Heading
                  variant="display"
                  scale={4}
                  css={socialMediaTitleStyle}
                  color="secondary"
                >
                  {t("blogPost.share.title")}
                </Heading>
                <SocialMediaShare
                  css={[marginBottomLg]}
                  signableTitle="Teile diesen Artikel:"
                  signableType="petition"
                  url="fdsfd"
                  context="fsfsd"
                  variant="secondary"
                  background="dark"
                  size={isSmallScreen ? "sm" : "sm"}
                  socialMediaList={[
                    "facebook",
                    "twitter",
                    "email",
                    "whatsapp",
                    "link",
                  ]}
                  injectSocialMediaUrl={true}
                  includeLabel={false}
                  transparent
                />
              </Column>
            </div>
          </Column>
        </Row>
      </Container>
      <Container size="lg">
        <Row>
          <Column
            span={{ default: 9, lg: 6, md: 6, xs: 9 }}
            offset={{ default: 3, md: 4, xs: 3 }}
          >
            <Heading
              variant="display"
              scale={3}
              color="primary"
              css={moreBlogsStyle}
            >
              Weitere Blog Posts
            </Heading>
          </Column>
        </Row>
        <Row>
          {morePosts?.map((blogPost) => {
            return (
              <CardColumn
                css={[marginBottomLg]}
                key={blogPost.id}
                span={{ default: 12, md: 6, lg: 4 }}
              >
                <BlogPostCard {...blogPost} />
              </CardColumn>
            );
          })}
        </Row>
        <Row>
          <Column
            span={{ default: 12, md: 3, xs: 12 }}
            offset={{ default: 0, md: 4, xs: 0 }}
          >
            <Button
              css={buttonStyle}
              as={Link}
              icon={faGalleryThumbnails}
              fullWidth={{ default: true }}
              to={"/blogs"}
              label={t("blogPost.buttonLabel")}
              variant="secondary"
            />
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default BlogPost;
