import { BlogPost } from "@Types/services/post";
// this file will be removed when datas are fetch from the database

export const data: BlogPost[] = [
  {
    id: "67657687980wefg099879",
    title: "In 6 Schritten zur eigenen Online-Petition",
    summary:
      "Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.",
    content: `<p>Auf innn.it kannst du deine eigene Petition starten, um die Welt um dich herum in deinem Sinne zu verändern. Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.Damit deine Petition Erfolg hat, zeigen wir dir hier die sechs wichtigsten Schritte.</p>
  <h5>1. Petition starten</h5>
  <p>Gehe auf innn.it und klicke auf den KnopfPetition starten.Um deine Petition zu veröffentlichen, benötigst Du ein innn.it-Konto. Bitte registriere dich oder melde dich an, wenn Du bereits ein Konto auf innn.it hast. Folge einfach den kurzen Tipps auf dem Bildschirm. Du wirst sehen: Eine Petition online zu stellen ist nicht schwieriger als ein Post auf Instagram.</p>
  <ol>
  <li>First item</li>
  <li>Second item</li>
  <li>Third item</li>
  <li>Fourth item</li>
  </ol>
  <h5>2. Finde eine klare Forderung!</h5>
  <p>Die Überschrift ist deine erste Chance, Leute von deiner Petition zu überzeugen und klarzumachen, was du ändern willst. Deine Überschrift ist gleichzeitig deine Forderung. Fasse dich kurz (weniger als 10 Wörter) und fasse deine Forderung möglichst konkret.Beispiel: „Prüft ein AfD-Verbot!“.Leser*innen wollen die Forderung genau verstehen, damit sie entscheiden können, ob sie deine Petition unterschreiben wollen. Die Überschrift sollte dringend und emotional sein. Wenn es ein wichtiges Datum oder Zeitdruck gibt, bringe das mit in die Überschrift hinein.Achte darauf, dass deine Forderung konkret und einzigartig ist, sodass sie auf Google leicht zu finden ist.Das Wort„prüfen“ beim AfD-Verbotist bei der Petition ein Alleinstellungsmerkmal.In diesem Video kannst du sehen(ab Min. 47’45), wie potenzielle Unterzeichner*innen die Petition einfach im Netz auffinden können.</p>
  <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111250/b41dc499d89980a8c040b878b1081b8f.png"></figure>
  <h5>3. Finde heraus, wer über dein Thema entscheiden kann!</h5>
  <p>Entscheidungsträger*innen sind diejenigen, die für dein Anliegen verantwortlich sind. Das können zum Beispiel Einzelpersonen in Unternehmen oder Politiker*innen sein.<br>Einzelpersonen eignen sich am besten, weil sie zur Verantwortung gezogen werden können. Sprichst du einfach die Bundesregierung an, fühlt sich im Zweifel niemand angesprochen. Daher überlege dir genau, wer zuständig ist. Innerhalb einer Organisation kannst du Einzelpersonen besser erreichen und bestenfalls überzeugen.<strong>Beispiel: “Oberbürgermeister*in von Frankfurt am Main”statt “Stadtverwaltung Frankfurt am Main”.</strong></p>
  <ul>
  <li>First item</li>
  <li>Second item</li>
  <li>Third item</li>
  <li>Fourth item</li>
  </ul>
  <h5>4. Beschreibe deine persönliche Geschichte</h5>
  <p>Medien berichten über dein Anliegen berichten, wenn du etwas zu erzählen hast.Aus diesem Grund ist deine persönliche Geschichte besonders wichtig– egal ob du selbst von dem Problem betroffen bist oder nicht. Stelle die handelnden Menschen in deiner Geschichte vor und erzähle, warum sie wichtig sind. Verdeutliche, was die Akteur*innen in der Zukunft bewegen wollen und welche Hindernisse ihnen im Weg stehen. Menschen (und Medien) interessieren sich für Menschen, die versuchen etwas zu bewegen, obwohl es schwierig für sie ist. Was erhoffen wir uns von der Geschichte und wovor haben wir Angst? Je mehr Konkretes auf dem Spiel steht, desto spannender ist die Geschichte. Stelle deutlich dar, was passiert, wenn du gewinnst, und was passiert, wenn du verlierst. Beispiel:Retten Sie meinen Vater, Frau Baerbock, Herr Scholz!</p>
  <blockquote>
  <p>„Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lobortis mi eget tincidunt commodo.”</p>
  </blockquote>
  <h5>5. Füge ein Bild hinzu</h5>
  <p>Neben der Forderung werden Leser*innen als erstes das Bild der Petition sehen. Dieses Foto erscheint auch in den Social Media Kanälen, sobald Menschen deine Petition teilen.Ein perfektes Foto verdeutlicht in Sekundenschnelle dein Thema.<strong>Lade ein horizontales Bild mit ausreichender Belichtung hoch, das möglichst über 1600×900 Pixel groß ist.</strong>Bei einem Tierthema zeige beispielsweise das betroffene Tier. Willst du Menschen helfen, zeige die betroffenen Menschen.</p>
  <p></p>
  <p><strong>Beispiel:NEIN zu Schnellabschüssen von Wölfen in Deutschland!</strong></p>
  <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111403/5356fa810034df664e32ef71b9348c88.png"></figure>
  <h5>6. Verbreite deine Petition &amp; finde Mitstreiter*innen</h5>
  <p>Nachdem du die Petition gestartet hast, kannst du sie einfach auf Instagram, TikTok, Facebook und X (ehem. Twitter) teilen. Logge dich ein und rufe deine Petitionsseite auf.Rechts neben dem Text findest du die Möglichkeit, die Petition über die verschiedenen Kanäle zu teilen.Zusätzlich zum Teilen der Petition im eigenen Netzwerk kannst du zum Beispiel den Link zu deiner Petition in den Kommentaren von relevanten Feeds zu dem Thema hinterlassen oder auch reichweitenstarke Personen, die zu deinem Thema passen, direkt ansprechen und darum bitten, deinen Post und den Link zur Petition zu teilen.Es bietet sich an, sie als Erstunterzeichner*innen für die Petition zu gewinnen und sie auch in der Petition zu nennen.
  Der Versand per E-Mail ist mindestens genauso wichtig wie die Verbreitung via Social Media.Bitte deine Freund*innen und Familie deine Petition zu unterschreiben und zu teilen, wie einen Kettenbrief.Wichtig ist nicht nur, an wen du die Kampagne per E-Mail sendest, sondern auch was du dazu schreibst. Fasse in drei Sätzen zusammen, was das Problem ist und erkläre, warum du dich für Veränderung einsetzt.</p>
  `,
    name: "rettet",
    featuredImage: {
      fileName: "6736109afc39ed3d8e36f446.png",
      id: "6736109afc39ed3d8e36f446",
      mimeType: "image/png",
      name: "header-image-text-above-3png",
      parent: "673289ec87fe08c386f4d620",
      status: "publish",
      title: "Header Image + Text Above (3).png",
      type: "attachment",
      updatedAt: "2024-11-14T15:00:46.730Z",
      urls: {
        original:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.png",
        lg: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.png",
        lgWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.webp",
        md: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.png",
        mdWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.webp",
        sm: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.png",
        smWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.webp",
        thumb:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.png",
        thumbWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.webp",
        webp: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.webp",
        xl: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.png",
        xlWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.webp",
      },
      content: "",
      authorId: "",
      hidden: false,
      _id: "6736109afc39ed3d8e36f446",
      createdAt: "",
    },
    keywords: ["Wahl", "Veränderung", "Gemeinnützigkeit", "Rettet"],
    authorId: "611a393ed1ff54001df69f66",
  },
  {
    id: "67327689878620",
    title: "„Der Kampf um unsere Gemeinnützigkeit“ – Das geschah bisher …",
    summary:
      "Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.",
    content: `<p>Auf innn.it kannst du deine eigene Petition starten, um die Welt um dich herum in deinem Sinne zu verändern. Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.Damit deine Petition Erfolg hat, zeigen wir dir hier die sechs wichtigsten Schritte.</p>
    <h5>1. Petition starten</h5>
    <p>Gehe auf innn.it und klicke auf den KnopfPetition starten.Um deine Petition zu veröffentlichen, benötigst Du ein innn.it-Konto. Bitte registriere dich oder melde dich an, wenn Du bereits ein Konto auf innn.it hast. Folge einfach den kurzen Tipps auf dem Bildschirm. Du wirst sehen: Eine Petition online zu stellen ist nicht schwieriger als ein Post auf Instagram.</p>
    <ol>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ol>
    <h5>2. Finde eine klare Forderung!</h5>
    <p>Die Überschrift ist deine erste Chance, Leute von deiner Petition zu überzeugen und klarzumachen, was du ändern willst. Deine Überschrift ist gleichzeitig deine Forderung. Fasse dich kurz (weniger als 10 Wörter) und fasse deine Forderung möglichst konkret.Beispiel: „Prüft ein AfD-Verbot!“.Leser*innen wollen die Forderung genau verstehen, damit sie entscheiden können, ob sie deine Petition unterschreiben wollen. Die Überschrift sollte dringend und emotional sein. Wenn es ein wichtiges Datum oder Zeitdruck gibt, bringe das mit in die Überschrift hinein.Achte darauf, dass deine Forderung konkret und einzigartig ist, sodass sie auf Google leicht zu finden ist.Das Wort„prüfen“ beim AfD-Verbotist bei der Petition ein Alleinstellungsmerkmal.In diesem Video kannst du sehen(ab Min. 47’45), wie potenzielle Unterzeichner*innen die Petition einfach im Netz auffinden können.</p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111250/b41dc499d89980a8c040b878b1081b8f.png"></figure>
    <h5>3. Finde heraus, wer über dein Thema entscheiden kann!</h5>
    <p>Entscheidungsträger*innen sind diejenigen, die für dein Anliegen verantwortlich sind. Das können zum Beispiel Einzelpersonen in Unternehmen oder Politiker*innen sein.<br>Einzelpersonen eignen sich am besten, weil sie zur Verantwortung gezogen werden können. Sprichst du einfach die Bundesregierung an, fühlt sich im Zweifel niemand angesprochen. Daher überlege dir genau, wer zuständig ist. Innerhalb einer Organisation kannst du Einzelpersonen besser erreichen und bestenfalls überzeugen.<strong>Beispiel: “Oberbürgermeister*in von Frankfurt am Main”statt “Stadtverwaltung Frankfurt am Main”.</strong></p>
    <ul>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ul>
    <h5>4. Beschreibe deine persönliche Geschichte</h5>
    <p>Medien berichten über dein Anliegen berichten, wenn du etwas zu erzählen hast.Aus diesem Grund ist deine persönliche Geschichte besonders wichtig– egal ob du selbst von dem Problem betroffen bist oder nicht. Stelle die handelnden Menschen in deiner Geschichte vor und erzähle, warum sie wichtig sind. Verdeutliche, was die Akteur*innen in der Zukunft bewegen wollen und welche Hindernisse ihnen im Weg stehen. Menschen (und Medien) interessieren sich für Menschen, die versuchen etwas zu bewegen, obwohl es schwierig für sie ist. Was erhoffen wir uns von der Geschichte und wovor haben wir Angst? Je mehr Konkretes auf dem Spiel steht, desto spannender ist die Geschichte. Stelle deutlich dar, was passiert, wenn du gewinnst, und was passiert, wenn du verlierst. Beispiel:Retten Sie meinen Vater, Frau Baerbock, Herr Scholz!</p>
    <blockquote>
    <p>„Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lobortis mi eget tincidunt commodo.”</p>
    </blockquote>
    <h5>5. Füge ein Bild hinzu</h5>
    <p>Neben der Forderung werden Leser*innen als erstes das Bild der Petition sehen. Dieses Foto erscheint auch in den Social Media Kanälen, sobald Menschen deine Petition teilen.Ein perfektes Foto verdeutlicht in Sekundenschnelle dein Thema.<strong>Lade ein horizontales Bild mit ausreichender Belichtung hoch, das möglichst über 1600×900 Pixel groß ist.</strong>Bei einem Tierthema zeige beispielsweise das betroffene Tier. Willst du Menschen helfen, zeige die betroffenen Menschen.</p>
    <p></p>
    <p><strong>Beispiel:NEIN zu Schnellabschüssen von Wölfen in Deutschland!</strong></p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111403/5356fa810034df664e32ef71b9348c88.png"></figure>
    <h5>6. Verbreite deine Petition &amp; finde Mitstreiter*innen</h5>
    <p>Nachdem du die Petition gestartet hast, kannst du sie einfach auf Instagram, TikTok, Facebook und X (ehem. Twitter) teilen. Logge dich ein und rufe deine Petitionsseite auf.Rechts neben dem Text findest du die Möglichkeit, die Petition über die verschiedenen Kanäle zu teilen.Zusätzlich zum Teilen der Petition im eigenen Netzwerk kannst du zum Beispiel den Link zu deiner Petition in den Kommentaren von relevanten Feeds zu dem Thema hinterlassen oder auch reichweitenstarke Personen, die zu deinem Thema passen, direkt ansprechen und darum bitten, deinen Post und den Link zur Petition zu teilen.Es bietet sich an, sie als Erstunterzeichner*innen für die Petition zu gewinnen und sie auch in der Petition zu nennen.
    Der Versand per E-Mail ist mindestens genauso wichtig wie die Verbreitung via Social Media.Bitte deine Freund*innen und Familie deine Petition zu unterschreiben und zu teilen, wie einen Kettenbrief.Wichtig ist nicht nur, an wen du die Kampagne per E-Mail sendest, sondern auch was du dazu schreibst. Fasse in drei Sätzen zusammen, was das Problem ist und erkläre, warum du dich für Veränderung einsetzt.</p>
    `,
    name: "rettet-2",
    featuredImage: {
      fileName: "6736109afc39ed3d8e36f446.png",
      id: "6736109afc39ed3d8e36f446",
      mimeType: "image/png",
      name: "header-image-text-above-3png",
      parent: "673289ec87fe08c386f4d620",
      status: "publish",
      title: "Header Image + Text Above (3).png",
      type: "attachment",
      updatedAt: "2024-11-14T15:00:46.730Z",
      urls: {
        original:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.png",
        lg: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.png",
        lgWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.webp",
        md: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.png",
        mdWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.webp",
        sm: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.png",
        smWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.webp",
        thumb:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.png",
        thumbWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.webp",
        webp: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.webp",
        xl: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.png",
        xlWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.webp",
      },
      content: "",
      authorId: "",
      hidden: false,
      _id: "6736109afc39ed3d8e36f446",
      createdAt: "",
    },
    keywords: ["Wahl", "Veränderung", "Gemeinnützigkeit", "Rettet"],
    authorId: "611a393ed1ff54001df69f66",
  },
  {
    id: "646f76898675f91c28578",
    title: "„Prüft ein AfD-Verbot!“ – Das geschah bisher …",
    summary:
      "Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.",
    content: `<p>Auf innn.it kannst du deine eigene Petition starten, um die Welt um dich herum in deinem Sinne zu verändern. Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.Damit deine Petition Erfolg hat, zeigen wir dir hier die sechs wichtigsten Schritte.</p>
    <h5>1. Petition starten</h5>
    <p>Gehe auf innn.it und klicke auf den KnopfPetition starten.Um deine Petition zu veröffentlichen, benötigst Du ein innn.it-Konto. Bitte registriere dich oder melde dich an, wenn Du bereits ein Konto auf innn.it hast. Folge einfach den kurzen Tipps auf dem Bildschirm. Du wirst sehen: Eine Petition online zu stellen ist nicht schwieriger als ein Post auf Instagram.</p>
    <ol>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ol>
    <h5>2. Finde eine klare Forderung!</h5>
    <p>Die Überschrift ist deine erste Chance, Leute von deiner Petition zu überzeugen und klarzumachen, was du ändern willst. Deine Überschrift ist gleichzeitig deine Forderung. Fasse dich kurz (weniger als 10 Wörter) und fasse deine Forderung möglichst konkret.Beispiel: „Prüft ein AfD-Verbot!“.Leser*innen wollen die Forderung genau verstehen, damit sie entscheiden können, ob sie deine Petition unterschreiben wollen. Die Überschrift sollte dringend und emotional sein. Wenn es ein wichtiges Datum oder Zeitdruck gibt, bringe das mit in die Überschrift hinein.Achte darauf, dass deine Forderung konkret und einzigartig ist, sodass sie auf Google leicht zu finden ist.Das Wort„prüfen“ beim AfD-Verbotist bei der Petition ein Alleinstellungsmerkmal.In diesem Video kannst du sehen(ab Min. 47’45), wie potenzielle Unterzeichner*innen die Petition einfach im Netz auffinden können.</p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111250/b41dc499d89980a8c040b878b1081b8f.png"></figure>
    <h5>3. Finde heraus, wer über dein Thema entscheiden kann!</h5>
    <p>Entscheidungsträger*innen sind diejenigen, die für dein Anliegen verantwortlich sind. Das können zum Beispiel Einzelpersonen in Unternehmen oder Politiker*innen sein.<br>Einzelpersonen eignen sich am besten, weil sie zur Verantwortung gezogen werden können. Sprichst du einfach die Bundesregierung an, fühlt sich im Zweifel niemand angesprochen. Daher überlege dir genau, wer zuständig ist. Innerhalb einer Organisation kannst du Einzelpersonen besser erreichen und bestenfalls überzeugen.<strong>Beispiel: “Oberbürgermeister*in von Frankfurt am Main”statt “Stadtverwaltung Frankfurt am Main”.</strong></p>
    <ul>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ul>
    <h5>4. Beschreibe deine persönliche Geschichte</h5>
    <p>Medien berichten über dein Anliegen berichten, wenn du etwas zu erzählen hast.Aus diesem Grund ist deine persönliche Geschichte besonders wichtig– egal ob du selbst von dem Problem betroffen bist oder nicht. Stelle die handelnden Menschen in deiner Geschichte vor und erzähle, warum sie wichtig sind. Verdeutliche, was die Akteur*innen in der Zukunft bewegen wollen und welche Hindernisse ihnen im Weg stehen. Menschen (und Medien) interessieren sich für Menschen, die versuchen etwas zu bewegen, obwohl es schwierig für sie ist. Was erhoffen wir uns von der Geschichte und wovor haben wir Angst? Je mehr Konkretes auf dem Spiel steht, desto spannender ist die Geschichte. Stelle deutlich dar, was passiert, wenn du gewinnst, und was passiert, wenn du verlierst. Beispiel:Retten Sie meinen Vater, Frau Baerbock, Herr Scholz!</p>
    <blockquote>
    <p>„Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lobortis mi eget tincidunt commodo.”</p>
    </blockquote>
    <h5>5. Füge ein Bild hinzu</h5>
    <p>Neben der Forderung werden Leser*innen als erstes das Bild der Petition sehen. Dieses Foto erscheint auch in den Social Media Kanälen, sobald Menschen deine Petition teilen.Ein perfektes Foto verdeutlicht in Sekundenschnelle dein Thema.<strong>Lade ein horizontales Bild mit ausreichender Belichtung hoch, das möglichst über 1600×900 Pixel groß ist.</strong>Bei einem Tierthema zeige beispielsweise das betroffene Tier. Willst du Menschen helfen, zeige die betroffenen Menschen.</p>
    <p></p>
    <p><strong>Beispiel:NEIN zu Schnellabschüssen von Wölfen in Deutschland!</strong></p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111403/5356fa810034df664e32ef71b9348c88.png"></figure>
    <h5>6. Verbreite deine Petition &amp; finde Mitstreiter*innen</h5>
    <p>Nachdem du die Petition gestartet hast, kannst du sie einfach auf Instagram, TikTok, Facebook und X (ehem. Twitter) teilen. Logge dich ein und rufe deine Petitionsseite auf.Rechts neben dem Text findest du die Möglichkeit, die Petition über die verschiedenen Kanäle zu teilen.Zusätzlich zum Teilen der Petition im eigenen Netzwerk kannst du zum Beispiel den Link zu deiner Petition in den Kommentaren von relevanten Feeds zu dem Thema hinterlassen oder auch reichweitenstarke Personen, die zu deinem Thema passen, direkt ansprechen und darum bitten, deinen Post und den Link zur Petition zu teilen.Es bietet sich an, sie als Erstunterzeichner*innen für die Petition zu gewinnen und sie auch in der Petition zu nennen.
    Der Versand per E-Mail ist mindestens genauso wichtig wie die Verbreitung via Social Media.Bitte deine Freund*innen und Familie deine Petition zu unterschreiben und zu teilen, wie einen Kettenbrief.Wichtig ist nicht nur, an wen du die Kampagne per E-Mail sendest, sondern auch was du dazu schreibst. Fasse in drei Sätzen zusammen, was das Problem ist und erkläre, warum du dich für Veränderung einsetzt.</p>
    `,
    name: "rettet-3",
    featuredImage: {
      fileName: "6736109afc39ed3d8e36f446.png",
      id: "6736109afc39ed3d8e36f446",
      mimeType: "image/png",
      name: "header-image-text-above-3png",
      parent: "673289ec87fe08c386f4d620",
      status: "publish",
      title: "Header Image + Text Above (3).png",
      type: "attachment",
      updatedAt: "2024-11-14T15:00:46.730Z",
      urls: {
        original:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.png",
        lg: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.png",
        lgWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.webp",
        md: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.png",
        mdWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.webp",
        sm: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.png",
        smWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.webp",
        thumb:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.png",
        thumbWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.webp",
        webp: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.webp",
        xl: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.png",
        xlWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.webp",
      },
      content: "",
      authorId: "",
      hidden: false,
      _id: "6736109afc39ed3d8e36f446",
      createdAt: "",
    },
    keywords: ["Wahl", "Veränderung", "Gemeinnützigkeit", "Rettet"],
    authorId: "611a393ed1ff54001df69f66",
  },
  {
    id: "67cd4587fe08c386f4d620",
    title: "Title of the blog article",
    summary:
      "Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.",
    content: `<p>Auf innn.it kannst du deine eigene Petition starten, um die Welt um dich herum in deinem Sinne zu verändern. Hier lernst du, wie du Unterstützung für dein Anliegen gewinnst und etwas verändern kannst.Damit deine Petition Erfolg hat, zeigen wir dir hier die sechs wichtigsten Schritte.</p>
    <h5>1. Petition starten</h5>
    <p>Gehe auf innn.it und klicke auf den KnopfPetition starten.Um deine Petition zu veröffentlichen, benötigst Du ein innn.it-Konto. Bitte registriere dich oder melde dich an, wenn Du bereits ein Konto auf innn.it hast. Folge einfach den kurzen Tipps auf dem Bildschirm. Du wirst sehen: Eine Petition online zu stellen ist nicht schwieriger als ein Post auf Instagram.</p>
    <ol>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ol>
    <h5>2. Finde eine klare Forderung!</h5>
    <p>Die Überschrift ist deine erste Chance, Leute von deiner Petition zu überzeugen und klarzumachen, was du ändern willst. Deine Überschrift ist gleichzeitig deine Forderung. Fasse dich kurz (weniger als 10 Wörter) und fasse deine Forderung möglichst konkret.Beispiel: „Prüft ein AfD-Verbot!“.Leser*innen wollen die Forderung genau verstehen, damit sie entscheiden können, ob sie deine Petition unterschreiben wollen. Die Überschrift sollte dringend und emotional sein. Wenn es ein wichtiges Datum oder Zeitdruck gibt, bringe das mit in die Überschrift hinein.Achte darauf, dass deine Forderung konkret und einzigartig ist, sodass sie auf Google leicht zu finden ist.Das Wort„prüfen“ beim AfD-Verbotist bei der Petition ein Alleinstellungsmerkmal.In diesem Video kannst du sehen(ab Min. 47’45), wie potenzielle Unterzeichner*innen die Petition einfach im Netz auffinden können.</p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111250/b41dc499d89980a8c040b878b1081b8f.png"></figure>
    <p>Entscheidungsträger*innen sind diejenigen, die für dein Anliegen verantwortlich sind. Das können zum Beispiel Einzelpersonen in Unternehmen oder Politiker*innen sein.<br>Einzelpersonen eignen sich am besten, weil sie zur Verantwortung gezogen werden können. Sprichst du einfach die Bundesregierung an, fühlt sich im Zweifel niemand angesprochen. Daher überlege dir genau, wer zuständig ist. Innerhalb einer Organisation kannst du Einzelpersonen besser erreichen und bestenfalls überzeugen.<strong>Beispiel: “Oberbürgermeister*in von Frankfurt am Main”statt “Stadtverwaltung Frankfurt am Main”.</strong></p>
    <ul>
    <li>First item</li>
    <li>Second item</li>
    <li>Third item</li>
    <li>Fourth item</li>
    </ul>
    <h5>4. Beschreibe deine persönliche Geschichte</h5>
    <p>Medien berichten über dein Anliegen berichten, wenn du etwas zu erzählen hast.Aus diesem Grund ist deine persönliche Geschichte besonders wichtig– egal ob du selbst von dem Problem betroffen bist oder nicht. Stelle die handelnden Menschen in deiner Geschichte vor und erzähle, warum sie wichtig sind. Verdeutliche, was die Akteur*innen in der Zukunft bewegen wollen und welche Hindernisse ihnen im Weg stehen. Menschen (und Medien) interessieren sich für Menschen, die versuchen etwas zu bewegen, obwohl es schwierig für sie ist. Was erhoffen wir uns von der Geschichte und wovor haben wir Angst? Je mehr Konkretes auf dem Spiel steht, desto spannender ist die Geschichte. Stelle deutlich dar, was passiert, wenn du gewinnst, und was passiert, wenn du verlierst. Beispiel:Retten Sie meinen Vater, Frau Baerbock, Herr Scholz!</p>
    <blockquote>
    <p>„Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lobortis mi eget tincidunt commodo.”</p>
    </blockquote>
    <h5>5. Füge ein Bild hinzu</h5>
    <p>Neben der Forderung werden Leser*innen als erstes das Bild der Petition sehen. Dieses Foto erscheint auch in den Social Media Kanälen, sobald Menschen deine Petition teilen.Ein perfektes Foto verdeutlicht in Sekundenschnelle dein Thema.<strong>Lade ein horizontales Bild mit ausreichender Belichtung hoch, das möglichst über 1600×900 Pixel groß ist.</strong>Bei einem Tierthema zeige beispielsweise das betroffene Tier. Willst du Menschen helfen, zeige die betroffenen Menschen.</p>
    <p></p>
    <p><strong>Beispiel:NEIN zu Schnellabschüssen von Wölfen in Deutschland!</strong></p>
    <figure class="image"><img src="https://innnit-blog.s3.eu-central-1.amazonaws.com/app/uploads/2024/12/10111403/5356fa810034df664e32ef71b9348c88.png"></figure>
    <h5>6. Verbreite deine Petition &amp; finde Mitstreiter*innen</h5>
    <p>Nachdem du die Petition gestartet hast, kannst du sie einfach auf Instagram, TikTok, Facebook und X (ehem. Twitter) teilen. Logge dich ein und rufe deine Petitionsseite auf.Rechts neben dem Text findest du die Möglichkeit, die Petition über die verschiedenen Kanäle zu teilen.Zusätzlich zum Teilen der Petition im eigenen Netzwerk kannst du zum Beispiel den Link zu deiner Petition in den Kommentaren von relevanten Feeds zu dem Thema hinterlassen oder auch reichweitenstarke Personen, die zu deinem Thema passen, direkt ansprechen und darum bitten, deinen Post und den Link zur Petition zu teilen.Es bietet sich an, sie als Erstunterzeichner*innen für die Petition zu gewinnen und sie auch in der Petition zu nennen.
    Der Versand per E-Mail ist mindestens genauso wichtig wie die Verbreitung via Social Media.Bitte deine Freund*innen und Familie deine Petition zu unterschreiben und zu teilen, wie einen Kettenbrief.Wichtig ist nicht nur, an wen du die Kampagne per E-Mail sendest, sondern auch was du dazu schreibst. Fasse in drei Sätzen zusammen, was das Problem ist und erkläre, warum du dich für Veränderung einsetzt.</p>
    `,
    name: "rettet-4",
    featuredImage: {
      fileName: "6736109afc39ed3d8e36f446.png",
      id: "6736109afc39ed3d8e36f446",
      mimeType: "image/png",
      name: "header-image-text-above-3png",
      parent: "673289ec87fe08c386f4d620",
      status: "publish",
      title: "Header Image + Text Above (3).png",
      type: "attachment",
      updatedAt: "2024-11-14T15:00:46.730Z",
      urls: {
        original:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.png",
        lg: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.png",
        lgWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_lg.webp",
        md: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.png",
        mdWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_md.webp",
        sm: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.png",
        smWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_sm.webp",
        thumb:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.png",
        thumbWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_thumb.webp",
        webp: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446.webp",
        xl: "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.png",
        xlWebp:
          "https://innnit-public.s3.eu-central-1.amazonaws.com/6736109afc39ed3d8e36f446_xl.webp",
      },
      content: "",
      authorId: "",
      hidden: false,
      _id: "6736109afc39ed3d8e36f446",
      createdAt: "",
    },
    keywords: ["Wahl", "Veränderung", "Gemeinnützigkeit", "Rettet"],
    authorId: "611a393ed1ff54001df69f66",
  },
];
