import React from "react";
import { Link } from "react-router-dom";
import { CSSObject } from "@emotion/react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { BlogPost } from "@Types/services/post";
import { Card, CardContent, CardHeader } from "../../emotion/components/Card";
import EllipsedText from "../../emotion/components/EllipsedContent";
import Sticker from "../../emotion/components/Sticker";
import { BodySm, Button, Column, Row } from "@Components";
import {
  colorPrimary,
  fontSizeMd,
  fontSizeXl,
  fontWeightMedium,
  marginBottomSm,
  marginMd,
  paddingLeftZero,
} from "@Styles";
import colors from "@Variables/colors";
import { formatDate } from "../../emotion/helpers/formatDate";
import { breakpointUp } from "@Variables/breakpoints";

const cardTitleStyle: CSSObject[] = [
  colorPrimary("petition"),
  fontWeightMedium,
  marginBottomSm,
  {
    [breakpointUp["xs"]]: [fontSizeXl],
    [breakpointUp["md"]]: [fontSizeXl],
  },
];

const BlogPostCard = (blogPost: BlogPost) => {
  const { i18n, t } = useTranslation();

  function calculateReadingTime(content: string) {
    content = content.replace(/<[^>]*>/g, "");
    const wordsPerMinute = 200; // Average reading speed
    const words = content.trim().split(/\s+/).length; // Count words
    const minutes = Math.ceil(words / wordsPerMinute); // Calculate reading time in minutes
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  }

  return (
    <Card {...blogPost}>
      <CardHeader
        alt={blogPost.name}
        attachment={blogPost.featuredImage}
        href={`/${blogPost.name}`}
        size="lg"
        css={{ borderRadius: "0.5rem" }}
      ></CardHeader>
      <CardContent>
        <Column
          span={{ default: 1 }}
          css={[marginBottomSm, paddingLeftZero]}
        >
          <Sticker
            label="Neuigkeit"
            style="default"
            variant="secondary"
          />
        </Column>
        <div css={cardTitleStyle}>
          <EllipsedText
            text={blogPost.title}
            lineClamp={{ default: 3, md: 3, xs: 2 }}
          />
        </div>
        <Row>
          <Column span={{ default: 6 }}>
            <BodySm css={{ color: colors.gray5 }}>
              {formatDate("2024-12-31T11:26:30.417Z", (i18n.language = "de"))}
            </BodySm>
          </Column>
          <Column css={{ textAlign: "right" }}>
            <BodySm css={{ color: colors.gray5 }}>
              Lesezeit: {parse(calculateReadingTime(blogPost.content))}
            </BodySm>
          </Column>
          <BodySm css={[fontSizeMd, marginMd]}>
            <EllipsedText
              text={blogPost?.summary}
              lineClamp={{ default: 2 }}
            />
          </BodySm>
        </Row>
        <Button
          css={{ marginTop: "auto" }}
          as={Link}
          fullWidth={{ default: true }}
          to={`/blogs/${blogPost.name}`}
          label={t("blogPost.cardButtonLabel")}
          variant="outline"
        />
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
